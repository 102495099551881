import Vue from 'vue'
import Vuex from 'vuex'
import axios from '@/shared/plugins/axios'
import utils from '@/shared/plugins/utils'

// we can access router now, but I think better will be to pass router data to action call from component
//import router from '../router'

import Address from './address/index'

Vue.use(Vuex)

const getDefaultState = () => {
    return {
        customer_ref: null,
        valuation_request_ref: null,
        request_language: null,
        loading: false,
        status: null,
        ovm_status: null,
        valuation_type: null,
        conflicting_valuation_requests: [],
        documents: [],
        borrower: null,
        valuer: null,
        ovm_info: null,
        transaction_value: undefined,
    }
}

const state = getDefaultState()

const valuationStore = {
    namespaced: true,
    state,
    getters: {
        getDefaultState,
        getFullValuationStore(state, getters, rootState, rootGetters) {
            let data = { ...state }
            data.Address = rootGetters['valuationStore/Address/getFullBuildingData']
            return data
        },
        isDraft(state) {
            return state.status === 'draft'
        },
        isWhitelabelOVM(state, getters, rootState, rootGetters) {
            return (
                state.valuation_type === 'ovm' &&
                rootGetters['auth/hasRole']('valuer', 'ovm')
            )
        },
        isBankOVM(state, getters, rootState, rootGetters) {
            return (
                state.valuation_type === 'ovm' &&
                !rootGetters['auth/hasRole']('valuer', 'ovm')
            )
        },
        getRequestRef(state) {
            return state.valuation_request_ref
        },
        getValuationType(state) {
            return state.valuation_type
        },
        getDocuments(state) {
            return state.documents
        },
        getRequestLanguage(state) {
            return state.request_language
        },
        borrower(state) {
            return state.borrower
        },
        valuer(state) {
            return state.valuer
        },
        status(state) {
            return state.status
        },
        getTransactionValue(state) {
            return state.transaction_value
        },
        ovm_status(state) {
            return state.ovm_status
        },
        ovmInfo(state) {
            return state.ovm_info
        },
    },
    mutations: {
        SET_CUSTOMER_REF(state, ref) {
            state.customer_ref = ref
        },
        SET_CONFLICTING_VALUATION_REQUESTS(state, conflicting_valuation_requests) {
            state.conflicting_valuation_requests = conflicting_valuation_requests
        },
        SET_VALUATION_REQUEST_REF(state, ref) {
            state.valuation_request_ref = ref
        },
        SET_REQUEST_LANGUAGE(state, lng) {
            state.request_language = lng
        },
        // update data from api store
        UPDATE_STATUS(state, data) {
            Object.assign(state, data)
        },
        SET_TRANSACTION_VALUE(state, data) {
            state.transaction_value = data
        },
        RESET_VAL(state) {
            Object.assign(state, getDefaultState())
        },
        SET_DOCUMENTS(state, documents) {
            state.documents = documents
        },
        SET_VALUER(state, valuer) {
            state.valuer = valuer
        },
        SET_BORROWER(state, borrower) {
            state.borrower = borrower
        },
        SET_LOADING(state, loading) {
            state.loading = loading
        },
    },
    actions: {
        fetch_documents(context) {
            return axios
                .get(
                    utils.urlJoin(Vue.prototype.$config.VALUATION_API_URL, [
                        '/request',
                        context.state.valuation_request_ref,
                        '/documents',
                    ])
                )
                .then((res) => {
                    let docs = res.data
                    docs.forEach((doc) => {
                        if (!doc.validation_purpose) doc.validation_purpose = doc.purpose
                    })
                    context.commit('SET_DOCUMENTS', docs)
                })
                .catch(() => {
                    context.commit('SET_DOCUMENTS', [])
                })
        },
        remove_document(context, document_ref) {
            let docs = context.state.documents.filter(
                (document) => document.document_ref != document_ref
            )
            context.commit('SET_DOCUMENTS', docs)
        },
        add_documents(context, newDocs) {
            let docs = [...context.state.documents, ...newDocs]

            context.commit('SET_DOCUMENTS', docs)
        },
        fetch_transaction_value(context) {
            return axios
                .get(
                    utils.urlJoin(Vue.prototype.$config.VALUATION_API_URL, [
                        '/request',
                        context.state.valuation_request_ref,
                        '/transaction_value',
                    ])
                )
                .then(({ data }) => {
                    context.commit('SET_TRANSACTION_VALUE', data.transaction_value)
                })
        },
        create_valuation_request(context, payload) {
            const { customer_ref, ignore_conflicts, requested_valuation_type } = payload
            let params = { customer_ref }
            if (ignore_conflicts) {
                params.ignore_conflicts = 'true'
            }
            if (requested_valuation_type) {
                params.requested_valuation_type = requested_valuation_type
            }
            return axios
                .post(utils.val_urls(Vue.prototype.$config).request, null, { params })
                .then((response) => {
                    context.commit(
                        'SET_VALUATION_REQUEST_REF',
                        response.data.valuation_request_ref
                    )
                    // context.commit('UPDATE_STATUS', { status: response.data.status })
                    context.commit('UPDATE_STATUS', { status: 'draft' })
                })
                .catch((error) => {
                    let response = error.response
                    if (
                        response.status === 403 &&
                        response.data.errors?.customer_ref_conflict
                    ) {
                        context.commit(
                            'SET_CONFLICTING_VALUATION_REQUESTS',
                            response.data.errors.customer_ref_conflict
                                .conflicting_valuation_requests
                        )
                    }
                })
        },
        load_valuation_request(context) {
            if (!context.state.valuation_request_ref) return

            context.commit('Address/RESET_STORE')

            return axios
                .get(
                    utils.urlJoin(Vue.prototype.$config.VALUATION_API_URL, [
                        'request',
                        context.state.valuation_request_ref,
                    ]),
                    { params: { details: 'full' } }
                )
                .then((result) => {
                    context.commit('UPDATE_STATUS', {
                        status: result.data.status,
                        ovm_status: result.data.ovm_status,
                        ovm_info: result.data.ovm_info,
                        valuation_type: result.data.valuation_type,
                        borrower: result.data.borrower,
                    })

                    if (typeof result.data.customer_ref !== 'undefined') {
                        context.commit('SET_CUSTOMER_REF', result.data.customer_ref)
                    }

                    if (typeof result.data.last_submission_lang !== 'undefined') {
                        context.commit(
                            'SET_REQUEST_LANGUAGE',
                            result.data.last_submission_lang
                        )
                    }

                    // commit address info
                    if (typeof result.data.address !== 'undefined') {
                        context.commit('Address/SET_ADDRESS', result.data.address)
                    }
                    if (typeof result.data.features !== 'undefined') {
                        context.commit('Address/SET_FEATURES', result.data.features)
                    }

                    if (typeof result.data.created_at !== 'undefined') {
                        context.commit('Address/SET_CREATED_AT', result.data.created_at)
                    }

                    if (
                        typeof result.data.document_info !== 'undefined' &&
                        !utils.isEmptyObject(result.data.document_info)
                    ) {
                        context.dispatch('fetch_documents')
                    } else {
                        context.commit('SET_DOCUMENTS', [])
                    }

                    if (
                        typeof result.data.borrower !== 'undefined' &&
                        !utils.isEmptyObject(result.data.borrower)
                    ) {
                        context.commit('SET_BORROWER', result.data.borrower)
                    }

                    if (
                        typeof result.data.valuer !== 'undefined' &&
                        !utils.isEmptyObject(result.data.valuer)
                    ) {
                        context.commit('SET_VALUER', result.data.valuer)
                    }

                    if (typeof result.data.unread_messages !== 'undefined') {
                        context.commit(
                            'SET_UNREAD_MESSAGES',
                            result.data.unread_messages,
                            { root: true }
                        )
                    }

                    if (typeof result.data.dvm_rejections !== 'undefined') {
                        context.commit(
                            'Address/SET_REJECTIONS',
                            result.data.dvm_rejections
                        )
                    }

                    context.dispatch('Address/fetchStreetviewInfo')

                    if (typeof result.data.avm_transaction !== 'undefined') {
                        context.commit('Address/SET_AVM', {
                            avm_transaction: result.data.avm_transaction,
                            avm_rental: result.data.avm_rental,
                        })
                    }
                    if (typeof result.data.last_dvm_rejection !== 'undefined') {
                        context.commit(
                            'Address/SET_LAST_DVM_REJECTION',
                            result.data.last_dvm_rejection
                        )
                    }
                    if (result.data.valuation) {
                        let date = result.data.valuation.date
                        if (Array.isArray(date)) {
                            date = date[0]
                        }
                        if (typeof date === 'string') {
                            date = utils.parseFUDate(date)
                        }

                        // If it's set, take it from valuation
                        // If it's not set but the request is not valued: default is false
                        // Else (aka if a valuation has been done in the past): default is true
                        let override_price =
                            typeof result.data.valuation.override_price !== 'undefined'
                                ? result.data.valuation.override_price
                                : !!result.data.valuation.date

                        if (override_price) {
                            let {
                                market_value,
                                forced_sale_value,
                                rental_value,
                                reconstruction_value,
                            } = result.data.valuation

                            let prices_override = {
                                market_value,
                                forced_sale_value,
                                rental_value,
                                reconstruction_value,
                            }

                            context.commit('Address/SET_PRICES_OVERRIDE', prices_override)
                        }

                        context.commit('Address/SET_VALUATION', {
                            ...result.data.valuation,
                            date: date,
                            reconstruction_value:
                                result.data.valuation.reconstruction_value &&
                                Vue.prototype.$config.RECONSTRUCTION_VALUE_ENABLED
                                    ? result.data.valuation.reconstruction_value
                                    : null,
                            override_price,
                            internal_remarks:
                                result.data.valuation.internal_remarks || '',
                        })
                    }
                    if (typeof result.data.dvm_features !== 'undefined') {
                        context.commit(
                            'Address/SET_DVM_FEATURES',
                            result.data.dvm_features
                        )
                    }
                    if (!utils.isEmptyObject(result.data.ovm_features)) {
                        context.commit(
                            'Address/SET_OVM_FEATURES',
                            result.data.ovm_features
                        )
                    }
                    if (!utils.isEmptyObject(result.data.map_scene2d)) {
                        context.commit('Address/SET_MAP_SCENE2D', result.data.map_scene2d)
                    } else if (result.data.features.building_id) {
                        context.dispatch('Address/fetch_map_data')
                    }
                    if (typeof result.data.map_scene3d !== 'undefined') {
                        context.commit('Address/SET_MAP_SCENE3D', result.data.map_scene3d)
                    }
                    if (typeof result.data.other_data !== 'undefined') {
                        context.commit('Address/SET_OTHER_DATA', result.data.other_data)
                    }

                    if (
                        !result.data.hasOwnProperty('other_data') ||
                        !result.data.other_data.hasOwnProperty('extra_info_edited')
                    ) {
                        const hasExtraInfo =
                            result.data.features.hasOwnProperty('f_construction_year') &&
                            !!result.data.features.f_construction_year
                        context.commit('Address/SET_OTHER_DATA', {
                            extra_info_edited: hasExtraInfo,
                        })
                    }

                    if (
                        context.rootGetters['auth/hasRole']('dispatcher', 'ovm') ||
                        result.data.owner?.is_self
                    ) {
                        context.dispatch('fetch_transaction_value')
                    }

                    context.commit('Address/SET_LOADED_STATE', true)
                })
        },
    },
    modules: {
        Address,
    },
}

export default valuationStore
